const getTooltipText = (feature, layerType, t) => {
    // const feature = data[0];
    const ehInterseccao = feature.properties?.OINTERSECAONUMERO ? true : false;
    const ehProjSub =
        feature.properties?.IGUCAMADA === "ProjetosUrbanosImplantados";
    const ehProjInt = feature.properties?.IGUCAMADA === "RedeInterligacao";
    const ehRotas = feature.properties?.IGUCAMADA === "RotasCorredor";
    const ehCoord = feature.properties?.IGUCAMADA === "BandasDeCoordenacao";
    const ehRedeSem = feature.properties?.IGUCAMADA === "RedeSemaforica";
    const ehSubRedeSem = feature.properties?.IGUCAMADA === "RedeSub";
    const ehRotasDesempenho =
        feature.properties?.IGUCAMADA === "RotasDesempenho";
    const ehLinhaBus = feature.properties?.IGUCAMADA === "RotasDeOnibus";
    const ehZoneamento = feature.properties?.Zona ? true : false;
    const ehAreaSim = feature.properties?.IGUCAMADA === "AreasSimulacao";
    const ehViabilidadeSem =
        feature.properties?.IGUCAMADA === "ViabilidadeSemaforica";
    const ehPontoOnibus = feature.properties?.IGUCAMADA === "PontosOnibus";
    const ehTopografia = feature.properties?.IGUCAMADA === "Topografia";

    let tooltipText;
    if (ehInterseccao) {
        const iSituacao = feature.properties.OINTERSECAOSITUACAO;
        let iDescricao;
        let iLocal;
        // const ehOD = feature.properties.OINTERSECAOSITUACAO.toLowerCase().includes("rotatoria");
        const iNum = feature.properties.OINTERSECAONUMERO;

        if (layerType === "interseccaoSemMap") {
            // if (
            //     feature.properties.OPROGRAMACAO === "Não" &&
            //     feature.properties.OCROQUI === "Não"
            // ) {
            //     iDescricao = "Mapeada";
            // } else
            if (iSituacao === "IntersecaoSemaforizada") {
                iDescricao = "Semaforizada";
            } else if (iSituacao.toLowerCase().includes("principal")) {
                iDescricao = "Principal";
            } else if (iSituacao.toLowerCase().includes("conjugado")) {
                iDescricao = "Conjugado";
            } else if (iSituacao.toLowerCase().includes("escopo")) {
                iDescricao = "Mapeada";
            } else if (iSituacao.toLowerCase().includes("naosemaforizada")) {
                iDescricao = "Sem Semáforo";
            } else if (iSituacao.toLowerCase().includes("semdocumentacao")) {
                iDescricao = "Semaforizada";
            }

            if (iDescricao === "Mapeada") {
                iLocal = "Só Planta";
            } else if (iSituacao.toLowerCase().includes("corredor")) {
                iLocal = "corredor";
            } else if (iSituacao.toLowerCase().includes("adjacente")) {
                iLocal = "adjacente";
            } else if (iSituacao.toLowerCase().includes("nova")) {
                iLocal = "nova";
            } else if (iSituacao.toLowerCase().includes("adversa")) {
                iLocal = "adversa";
            } else if (iSituacao.toLowerCase().includes("escopo")) {
                iLocal = "Só Planta";
            } else if (iSituacao.toLowerCase().includes("semdocumentacao")) {
                iLocal = "Sem Documentação";
            } else {
                iLocal = "existente";
            }
            tooltipText =
                iLocal === "adversa"
                    ? "Intersecção Situação Adversa"
                    : iSituacao === "IntersecaoSemaforizada"
                    ? t("intSem")
                    : `Intersecção ${iDescricao} (${iLocal})`;
        } else if (layerType === "contagemVeic") {
            tooltipText = `${t("cont")}: ${iNum}`;
        } else if (layerType === "viabilidadeSem") {
            tooltipText = `Intersecção em avaliação: ${iNum}`;
        } else {
            tooltipText = `${t("contOD")} ${iNum}`;
        }
    } else if (ehProjSub) {
        tooltipText = `${t("projSub")}: ${feature.properties.IGUNOME}`;
    } else if (ehAreaSim) {
        tooltipText = `${t("sim")}: ${feature.properties.IGUNOME}`;
    } else if (ehProjInt) {
        tooltipText = `${t("projInt")}: ${feature.properties.IGUNOME}`;
    } else if (ehRotas) {
        tooltipText = `${t("cor")} ${feature.properties.IGUNOME}`;
    } else if (ehCoord) {
        tooltipText = `${feature.properties.IGUNOME}`;
    } else if (ehRedeSem) {
        tooltipText = `${t("redeSem")} ${feature.properties.IGUNOME}`;
    } else if (ehSubRedeSem) {
        tooltipText = `${t("subRedeSem")} ${feature.properties.IGUNOME}`;
    } else if (ehRotasDesempenho) {
        tooltipText = `${t("route")} ${feature.properties.IGUNOME}`;
    } else if (ehLinhaBus) {
        tooltipText = `${feature.properties.IGUNOME} ${feature.properties.sentido}`;
    } else if (ehViabilidadeSem) {
        tooltipText = `${t("viaRep")} (${feature.properties.IGUNOME})`;
    } else if (ehZoneamento) {
        tooltipText = String(
            feature.properties.Zona.toLocaleString("en-US", {
                minimumIntegerDigits: 3,
                useGrouping: false,
            })
        );
    } else if (ehPontoOnibus) {
        tooltipText = `${t("pontosBus")} ${feature.properties.IGUNOME}`;
    } else if (ehTopografia) {
        tooltipText = `${t("top")} ${feature.properties.IGUNOME}`;
    }
    return tooltipText;
};

export { getTooltipText };
